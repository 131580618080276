import * as React from 'react'
import { useQuery } from '@apollo/client'
import { IntercomSessionData } from '../api-documents'
import { loadSnippet } from '../loadSnippet/loadSnippet'
import { APP_ID } from '../getAppId'
import { PersonName } from '@local/grouter'
import { useCompanyCode } from '@toasttab/ec-session'

/**
 * IntercomChat component
 */
export const IntercomChat = () => {
  const pathname = window.location.pathname

  const { data, loading } = useQuery(IntercomSessionData, {
    variables: { clientType: 'WEB' }
  })
  const companyCode = useCompanyCode()
  React.useEffect(() => {
    if (!loading) {
      loadSnippet()

      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: APP_ID,
        user_hash: data?.intercomSessionData?.intercomUserHash || undefined,
        user_id: data?.intercomSessionData?.user?.id,
        email: data?.intercomSessionData?.user?.email || undefined,
        name:
          formatName(data?.intercomSessionData?.user?.name as PersonName) ||
          undefined,
        company_code: companyCode
      })
    }
  }, [data, loading, companyCode])

  React.useEffect(() => {
    if (!loading) {
      window.Intercom('update')
    }
  }, [pathname])

  return null
}

const formatName = (name: PersonName): string => {
  const firstName = name?.chosen || name?.first
  return `${firstName} ${name?.last}`.trim()
}
